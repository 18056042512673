import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LayoutWithMainContent } from '../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../lib/constants';
import { Spinner } from '../components/spinner/Spinner';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';

export const LoggedOut = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const previousUrl = localStorage.getItem('previousUrl');
      if (previousUrl && previousUrl.includes('mitt-naf')) {
        history.push('/');
        localStorage.removeItem('previousUrl');
      } else {
        const { pathname } = new URL(previousUrl);
        history.push(pathname || '/');
        localStorage.removeItem('previousUrl');
      }
    }
  }, [isAuthenticated, isLoading, history]);

  return (
    <LayoutWithMainContent
      title="Logger ut..."
      description="Du blir logget ut"
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'Logger ut',
        pageCategory: '',
        contentId: 'logging-out',
      }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      noIndex
    >
      {isLoading && <Spinner />}
    </LayoutWithMainContent>
  );
};
